.container {
  position: relative;
  max-width: 90%;
}

.previewSection {
  background-color: rgb(230, 214, 196);
  position:fixed;
  top:10vh;
  right:  0px;
  height:80vh;
  z-index: 100001;
  border: 3px solid gray;
  transition: right 0.5s;
  width:900px;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

div.previewSection h2 {
  background-color: rgb(140, 102, 58);
  padding:15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  color: white;
  text-align: center;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.poppedOutPreview {
  right:0;
  overflow-y:auto;
  overflow-x:auto;
}
.poppedInPreview {
  right:-900px;
  overflow-y: hidden;
  overflow-x: hidden;
}
.poppingPreview {
  right:-840px;
}
.previewPin,.collapseDefine {
  height:40px;
  width:40px;
  position:absolute;
  border-top-left-radius: 10px;
  cursor:pointer;
  opacity:0.4;
  margin-top:5px;
}
.previewPin:hover,.collapseDefine:hover {
  opacity:1;
}
.previewPinImage {
  height:35px;
  width:35px;
  margin-top:5px;
}
#blocklyDiv {
  margin:40px;
  width:90vw;
  height:80vh;  
  text-align: center;
  /* border:2px solid black; */
  }
.previewSection table {
  background-color: rgb(242, 235, 229);
  border-radius: 15px;
}
td.failed {
  background:yellow;
  color:black;
}
td.passed {
  background: inherit;
  color: black;
}


.tabBar {
  margin-top:45px;
}
.topTab {
  float:left;
  cursor:pointer;
}
.topTab:hover {
  scale:1.02;
}
.defineTopTab,.evalTopTab,.homeTopTab,.exploreTopTab {
  background-color: rgb(28, 80, 97);
  padding:3px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 1.4em;
  color: white;
  text-align: center;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  min-width:175px;
  height:40px;
  margin:auto;
  margin-right:2px;
  z-index: 10000;
  position:relative;
}
.evalTopTab {
  background-color: rgb(36, 97, 28);
}
.homeTopTab {
  background-color: rgb(45, 137, 137);
  color:white;
}
.exploreTopTab {
  background-color: rgb(155, 99, 26);
}
/* .previewTopTab {
  background-color: rgb(97, 28, 59);
} */
.activeTab {
  scale:1.05;
  margin-top:-2px;
  border:2px solid darkgray;
  border-bottom: none;
  height: 43px;
  z-index: 10001;
  box-shadow: 3px 0px gray;
  cursor:default;
}
.activeTab:hover {
  scale:1.05;
}

.disabledTab {
  opacity:0.4;
  cursor:default;
}
.disabledTab:hover {
  scale:1;
}
.introIconTab {
  font-weight:bold;
  font-size:1.5em;
  border:1px solid black;
  width:35px;
  height:35px;
  border-radius: 30px;
  text-align: center;
  margin:5px;
  margin-bottom:0px;
}
.introIconTab:hover {
  opacity:1;
}

.homeSection {
  clear:both;
  background: rgb(20,81,114);
background: linear-gradient(-45deg, rgb(52, 101, 98) 0%, rgb(106, 172, 161) 30%, rgb(233, 255, 254) 100%);
border: 3px solid gray;
}
.customCategory {
  padding:5px 15px 5px 15px;
  text-align:left;
}
.customIcon {
  margin-right: 5px;
}
.evalSection {
  clear:both;
  border: 3px solid gray;
  background-color: rgb(174, 200, 180);
}

.exploreSection {
  clear:both;
  border: 3px solid gray;
  background-color: rgb(234, 227, 206);
background: linear-gradient(-45deg, rgb(176, 146, 98) 0%, rgb(239, 222, 185) 40%, rgb(234, 227, 206) 100%);
}

.saveModelButton {
  position:absolute;
  left:50px;
  top:13px;
  width:45px;
  height:45px;
}
.previewed {
  font-weight: bold;
}
.modelErrorNotice {
  color: red;
  font-weight: bold;
  float:left;
  width:auto;
}
.runModel {
  position:absolute;
  right:0px;
  top:10px;
  width:160px;
  color:white;
}
.runningModel {
  display:none;
  color: white;
  background-color: green;
  float:left;
  width:auto;
}
#fileName {
  border:1px solid red;
  width:45px;
  height:45px;
  position:relative;
  top:-54px;
  left:-5px;
  visibility: hidden;
}
.lastRun {
  float:left;
  width:auto;
}
.linkToPopOutDefine {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
.remove {
  cursor:pointer;
  background:white;
  color:red;
  opacity: 0.3;
  border:none;
  border-radius: 4px;
  width:25px;
}
.remove:hover {
  opacity:1;
  background:darkred;
  color:white;
}



.transferDefine {
  float:left;
}
.transferTooltip {
  position:absolute;
  display:none;
  background:white;
  border:1px solid gray;
  font-size:11px;
  color:black;
  cursor:pointer;
}
.transferTooltip:hover {
  display:block;
}
.transferDefine:hover~.transferTooltip {
  display:block;
}

