.homeSection {
    display:flex;
    flex-direction: row;
    gap: 100px;
}
.homeCol {
    display: flex;
    flex-direction: column;
    border-left: 1px solid gray;
}
.homeListStudyName {
    cursor:pointer;
}
.homeListStudyName:hover {
    border-bottom:1px solid gray;
    font-weight:bold;
}
.homeListStudyName.expanded {
    font-weight:bold;
    cursor:default;
}
.homeListStudyName.expanded:hover {
    border:none;
}

.loadFile {
    background: rgba(233, 233, 233,0.6);
    border: 2px solid darkslategray;
    width:30px;
    height: 30px;
    cursor:pointer;
    margin: 5px;
    padding:5px;
    overflow:hidden;
  }
  .loadFile:hover {
    scale: 1.1;
  }
  .openSaveIcon {
    width:35px;
    height:35px;
    font-size:18px;
  }
  .homeSectionLoadText {
    opacity:0.5;
    cursor: pointer;
  }
  .homeSectionLoadText:hover {
    color:darkgray;
    opacity:1;
    text-decoration: underline;
  }
  .saveFile {
    height:30px;
    width:30px;
    padding:3px;
    padding-left:6px;
    margin:5px;
    cursor: pointer;
    border: 2px solid darkslategray;
    background: rgba(233,233,233,0.6);
    border-radius:15px;
  }
  .saveFile:hover {
    scale:1.1;
  }
  .loadFile,.saveFile{
    position:relative;
  }
  .newProjectIcon {
      cursor: pointer;
      font-size: 1.3em;
      margin:10px;
  }
  .newProjectIcon:hover {
    cursor: pointer;
    scale:1.1;
  }