.variableHeader {
    font-weight:bold;
}
.epochWeightRow:hover {
    border:1px solid black;
    font-weight:bold;
}
.markovVariableHeader {
    font-size:1.2em;
    font-weight:bold;
    background-color: #ece1d2;
    box-shadow: 3px 3px darkgray;
    border:1px dashed gray;
    border-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
.varCrossVarRules {
    border-bottom: 1px dotted black;
    cursor: pointer;
    padding:3px;
    margin-right: 10px;
}
.markovTable {
    box-shadow:3px 3px darkgray;
    border:1px dashed gray;
    background:#e9dcce;
}
.eraCreationRowHeader {
    border-top: 1px dashed gray;
    margin: 20 0px;
    padding-top: 20px;
}

.eraEVLevelEditorCell {
    opacity:0;
}

.eraEVLevelEditorCell:hover {
    font-weight: bold;
    opacity:0.3;
    border-radius: 5px;
}
.eraEVLevelEditorSelected {
    opacity: 1;
    position:relative;
    
    border-radius: 5px;
}
.eraEVLevelEditorSelected:hover {
    opacity: 1;
    border-radius: 5px;
}
.disabledLevelEditorCell {
    opacity: 1;
    background-color:lightgray !important;
}
.errorCell {
    opacity: 1;
    background-color:red !important;
}
.unallocatedWeight {
    cursor: pointer;
    font-weight: bold;
    color: darkgoldenrod;
}
