.notesDefineDiv {
  position:fixed;
  right:-465px;
  top:240px;
  width:500px;
  height:45px;
  overflow:hidden;
  z-index:20000;
  background-color: #fafafa;
  border:2px solid gray;
  transition: all ease-out .5s;
  border-radius: 12px;
}
.poppedOut {
  right:0px;
  height:60vh;
  border-radius:6px;
}
.notesSectionTitle {
  color: black;
  background:rgb(231, 158, 170);
  padding:6px 10px 4px 10px;
  cursor:pointer;
}
.notesBody {
  overflow-y: auto;
  height:50vh;
}
.notesDefineDiv:hover {
  right:-460px;
}
.poppedOut>.notesSectionTitle {
  padding:10px;
}
.poppedOut:hover {
  right:0px;
}