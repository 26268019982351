
.defineSection {
  clear:both;
  background-color: rgb(167, 191, 210);
  border: 3px solid gray;
  min-width:870px;
  width:100vw;
  overflow:hidden;
  position:relative;
}
.responseAnim
{
  background-image: linear-gradient(
    -225deg,
    #ffffff 0%,
    #cecdd0 29%,
    #f8ffe2 67%,
    #ffffff 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 6s linear infinite alternate;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
    transform: rotateY(30deg);
  }
}
.activeSectionMATE {
  background-color: rgb(167, 191, 210);
  background: linear-gradient(180deg, rgb(152, 188, 208) 0%, rgb(167, 191, 210) 56%, rgb(192, 220, 232) 100%);
}

.activeSectionEEA {
  background-color: rgb(184, 158, 132);
}

.activeSectionChange {
  background-color: rgb(199, 212, 166);
}


.linkToStakeholderPrefs {
    text-decoration: underline;
    font-weight:bold;
    cursor: pointer;
  }
  .vmTable {
    border: 10px solid slategray;
    border-radius:5px;
  }
  .vmTable td, th, tr {
    border: none;
  }
.MATESection,.EEASection,.ChangeSection {
  padding:10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  position:relative;
  top:-4px;
  z-index: 1;
  overflow-x: auto;
}
.MATESection {

    border:2px solid rgb(44, 88, 141);

  }
  .EEASection {
    border:2px solid rgb(121, 97, 56);

  }
  .ChangeSection {
    border: 2px solid rgb(90, 106, 49);
  }
.vmTableDCHeader {
  font-size:1.3em;
  font-weight:bold;
}

.vmStakeholderName {
  font-size:1.4em;
  background-color: slategray;
  color: white;
  padding:5px 15px 5px 15px;
  border-radius:5px;
  font-weight:bold;
  width:50%;
  cursor:pointer;
}
.vmDvCvName {
  font-weight: bold;
}
.vmSeparatorRow {
  border:none;
  height:10px;
  background-color: slategray;
}
.vmLevs {
  
  cursor:pointer;
  margin:1px;
}
.vmDvIcon {
  color:darkslategray;
  cursor:pointer;
}

.disabledRow {
  opacity:0.3;
}
.editStakeholderPrefs {
  text-decoration: underline;
  font-weight: bold;
  cursor:pointer;
}

.dvsSection {
  margin-left:auto;
  margin-right: auto;
  padding:10px;
 background-color: #f0f0f0;
 border-radius: 10px; 
}
  .navTab {
    float:left;
    margin-left:12px;
    margin-right:12px;
    cursor:pointer;
    filter: saturate(0.4);
    user-select: none;
    padding:6px 6px 6px 6px;
  }
  .navMATE,.navEEA,.navChange {
    float:left;
    overflow:hidden;
    border-top-left-radius:15px;
    border-top-right-radius:15px;
    opacity:0.8;
    margin-top:10px;
    transition: all 0.1s 0s ease;
  }
  .navMATE {
    color: rgb(10, 73, 151);
    border:2px solid rgb(100, 131, 169);
    border-bottom:0px;
    background: linear-gradient(180deg, rgb(191, 209, 219) 0%, #f0f0f0 100%);
  }
  .dvLevs {
    cursor: pointer;
    position:relative;
  }
  .baseline {
    font-weight: bold;
    cursor: default;
  }
  /* thanks https://blog.logrocket.com/creating-beautiful-tooltips-with-only-css/ */
  .baseline:before {
    content: "baseline"; /* here's the magic */
    position:absolute;
    
    /* vertically center */
    top:0;
    transform:translateY(-50%);
    
    /* move to right */
    left:100%;
    margin-left:0px; /* and add a small left margin */
    
    /* basic styles */
    font-size: 0.8em;
    width:80px;
    padding:3px;
    border-radius:5px;
    background:#000;
    color: #fff;
    opacity:0.5;
    text-align:center;
  
    display:none; /* hide by default */
  }
  .baseline:hover:before {
    display:block;
  }

  .navEEA {
    color: rgb(86, 58, 27);
    border: 2px solid rgb(151, 127, 102);
    
    background: linear-gradient(180deg, rgb(203, 168, 132) 0%, #f0f0f0 100%);
    border-bottom:none;
  }  
  .navChange {
    color: rgb(86, 110, 25);
    border: 2px solid rgb(147, 163, 106);
    background: linear-gradient(180deg, rgb(185, 200, 145) 0%, #f0f0f0 100%);
    border-bottom: none;
  }
  .navMATE.activeNavSection {
    border:2px solid rgb(41, 82, 132);
    background: linear-gradient(180deg, rgb(152, 188, 208) 0%, #f0f0f0 100%);
  }
  .navEEA.activeNavSection {
    border: 2px solid rgb(107, 80, 52);
    background: linear-gradient(180deg, rgb(160, 109, 57) 0%, #f0f0f0 100%);
  }
  .navChange.activeNavSection {
    border: 2px solid rgb(90, 106, 49);
    background: linear-gradient(180deg, rgb(153, 174, 100) 0%, #f0f0f0 100%);
  }
  .activeNavSection {
    opacity:1;
    padding: 6px 6px 0px 6px;
    margin-top:4px;
    border-bottom:none;
  }
  .customNavIcon {
    position:absolute;
    left:-5px;
    top:-5px;
    background: rgb(235, 235, 235);
    border-radius: 15px;
  }
  .customNavIconEye {
    font-size:1.2em;
    left:50%;
  }
  .navTabError {
    position:absolute;
    top:15px;
    right:0;
  }
  .navTabWarning {
    position:absolute;
    top:15px;
    left:0;
  }
  
  .navTabUser {
    position:absolute;
    top:0;
    left:0;
    color: white;
    background-color: black;
    border-radius: 8px;
  }
  .sectionError {
    border:1px solid white;
    box-shadow: 3px 3px gray;
    border-radius:10px;
    padding:3px;
    background-color: darkred;
    color:white;
    margin:5px;
  }
  .sectionWarning {
    border:1px dotted white;
    box-shadow: 3px 3px gray;
    border-radius:10px;
    padding:3px;
    background-color: goldenrod;
    color:black;
    margin: 5px;
  }
  .navTabIcon {
    font-size:1.8em;
  }
  .navTab:hover {
    transform: scale(1.1)
  }
  .disabledNavTab {
    opacity:0.1;
    filter: saturate(0);
    cursor:default;
  }
  .disabledNavTab:hover {
    transform:scale(1);
  }
  .activeNavTab {
    filter: saturate(1);
    transform:scale(1.1);
    background-color: #f0f0f0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid gray;
    border-bottom:none;
    position:relative;
    z-index:2;
  }
  .activeNavTab:hover {
    transform:scale(1.1);
  }
  .ascending {
    rotate: 270deg;
    }
    .descending {
      rotate: 90deg;
      transform: scaleX(-1)
    }
  .dcDetails {
    font-size:1.3em;
  }
  .noPrefs {
    opacity:0.5;
  }
  .changePrefs {
    cursor: pointer;
    width:35px;
    height:35px;
  }
  .removeIcon {
    cursor:pointer;
    color:darkred;
    opacity: 0.1;
    filter:saturate(0);
    width:25px;
  }
  .removeIcon:hover {
    opacity:1;
    filter:saturate(1);
  }
 .fa-exclamation-circle {
  color: goldenrod;
  cursor:default;
 }
 .fa-signal {
  rotate:90deg;
  transform: scaleX(-1)
 }
 .fa-th {
  font-size: 1.3em;
 }
 .fa-th.low  {
  color:rgb(46, 10, 0);
  clip-path: inset(40% 40% 40% 40%);
 }
 .fa-th.med {
  color:rgb(129, 28, 0);
  clip-path: inset(32% 0 35% 0)
 }
 .fa-th.high {  
  color:rgb(209, 45, 0);
 }
 .fa-ban.noPrefs {
  opacity: 0.1;
  font-size:1.3em;
 }
 .editStakeholderPrefs > a {
  position:absolute;
 }
 .decisionCriteriaEditModal,.pathEnablersEditModal {
  position: fixed;
  top:20vh;
  left:10vw;
  right:auto;
  width:80vw;
  background-color: #f0f0f0;
  border: 3px solid darkslateblue;
  box-shadow: 5px 5px rgba(0,0,0,0.6);
  border-radius: 10px;
  padding: 20px;
  z-index: 3;
 }

 .editLevelRow {
  font-size: 1.3em;
}
.editModalCloseButton {
  width:20px;
  height:20px;
  cursor: pointer;
  position:absolute;
  right:10px;
  top:10px;
}
.editModalCloseButton:hover {
  color: red;
}

#bg-wrap {
  position:absolute;
  height:100%;
  width:100%;
}
.defineSectionTitle {
  font-size:3em;
  width:300px;
  rotate: -90deg;
  position:fixed;
  left:-120px;
  top:220px;
  opacity: 0.3;
  font-weight: bold;
  color:white;
  text-align: right;
  margin-top: 20px;
}
.redColor {
  color: red;
  box-shadow: 0 0 5px red;
}
.transitionAll {
  transition:all 1s linear;
}