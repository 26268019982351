.undoDefineDiv {
  position:fixed;
  right:-465px;
  top:140px;
  width:500px;
  height:45px;
  overflow:hidden;
  z-index:20000;
  background-color: #fafafa;
  border:2px solid gray;
  transition: all ease-out .5s;
  border-radius: 12px;
}
.undoSectionTitle {
  background: rgb(96, 155, 227);
  padding:6px 10px 4px 10px;
  cursor:pointer;
}
.undoBody {
  overflow-y: auto;
  height:50vh;
}
.undoDefineDiv:hover {
  right:-460px;
}
.poppedOut>.helpSectionTitle {
  padding:10px;
}
.userAndTime {
  font-size: 0.8em;
  margin-left: 25px;
}
.currentRedoStep {
  font-weight: bold;
}