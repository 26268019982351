.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.signinbar {
  margin-right:25px;
  background-color: white;
  cursor:pointer;
  position:fixed;
  top:0px;
  right:0px;
}
.titlebar {
  z-index: 100002;
  border-bottom:1px solid gray;
/* Make sure the `div` stays there, even when scrolling. */
position: fixed;
background-color: white;
 background-image: linear-gradient(90deg, rgb(176, 176, 176), #6f6f6f, 33%);

left: env(titlebar-area-x, 0);
top: env(titlebar-area-y, 0);
width: env(titlebar-area-width, 100%);
height: env(titlebar-area-height, 33px);
-webkit-app-region: drag;
display:flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#formProblemName {
  width:300px;
}
.nameButton {
  width:30px;
  height:30px;
  border:1px solid black;
  margin-left:5px;
  margin-top:3px;
  padding-left: 6px;
}
.inputName {
  display:flex;
  flex-direction: row;
}
.studyName {
    max-width:600px;
    cursor:pointer;
    font-size:20px;
    font-weight:bold;
    text-align: center;    
    border-radius:10px;
    overflow:hidden;
    padding: 0px 10px 0px 10px;
}
.editStudyNameIcon {
  display:none;
  margin-left:10px;
}
.studyName:hover {
  border: 3px dashed gray;
  opacity: 0.8;
}
/* >.editStudyNameIcon {
  display:inline;
} */
#offlineIndicator {
  background-color: goldenrod;
  color: black;
  font-weight: bold;
  border-radius: 5px;
  width:150px;
  text-align: center;
  display:none;
}