.helpDefineDiv {
  position:fixed;
  right:-465px;
  width:500px;
  height:45px;
  overflow-y:scroll;
  z-index:20000;
  background-color: #fafafa;
  border:2px solid gray;
  transition: all ease-out .5s;
  border-radius: 12px;
}
.helpSectionTitle {
  background: rgb(95, 214, 113);
  padding:6px 10px 4px 10px;
  cursor:pointer;
}
.helpDefineDiv:hover {
  right:-460px;
}
.poppedOut>.helpSectionTitle {
  padding:10px;
}
.subScript {
  font-size:0.5em;
  position:relative;
  top:10px;
}

.details {
  margin-left:50px;
  border-bottom:1px solid gray;
  transition: height 0.5s;
}
details summary {
    transition: margin 0.3s ease-out;
}
details[open] summary {
    margin-bottom: 10px;
}
.detailsSummary {
    padding:10px;
}
.details>div {
  margin-left:20px;
}
.defineDetails,.evalDetails,.generateDetails {
  background-color: lightblue;
  padding:5px;
  border-radius:3px;
  margin:5px;
}
.smallNote {
  font-size: 0.8em;
  color: #444400
}
.smallIcon {
  width:20px;
  height:20px;
}